<template>
  <div class="cardDriver">
    <div class="cardDriver-content">
      <div class="btnClose" @click="sendEvent()">
        <CIcon name="cil-x" height="25" />
      </div>

      <div class="cardDriver-title">{{ title }}</div>
      <hr />

      <div v-if="notification">
        <CAlert :color="notificationType">
          {{ notification }}
        </CAlert>
      </div>
      <div class="divProducts">
        <div
          class="cardDriver-subtitle btn-plus"
          @click="collapseProducts = !collapseProducts"
        >
          {{$t('available_products')}}
          <CIcon name="cil-plus" />
        </div>
        <!-- <CCollapse :show="showCollapseProducts == collapseProducts"> -->
        <!-- <BlockInfoSelect
                    title="Available products"
                    :values="availableProducts"
                    :disabled="false"
                  /> -->
        <div v-for="item in availableProducts" :key="item">
          <div class="form-check product-selector">
            <input
              class="form-check-input"
              type="checkbox"
              :id="'product_' + item"
              v-model="selectedProducts"
              :value="item"
            />
            <label class="form-check-label" :for="'product_' + item">{{
              item == 'aggregates' ? 'AGGREGATES/ASPHALT' : item.toUpperCase()
            }}</label>
          </div>
        </div>
        <!-- <div class="cardDriver-sectionButtons" >
                     <CButton
                      :color="btnEventType"
                      size="lg"
                      class="cardDriver-button"
                      @click="saveProducts()"
                      :disabled="selectedProducts.length == 0"
                      >Save</CButton
                      >
                    </div> -->
        <!-- </CCollapse> -->
      </div>
      <div v-if="_usersRCS">
        <div
          class="cardDriver-subtitle btn-plus"
          @click="collapseTop = !collapseTop"
        >
          {{$t('rcs_user_corresponding')}}
          <CIcon name="cil-plus" />
        </div>

        <CCollapse :show="showCollapseTop == collapseTop">
          <BlockInfoSelect
            :title="$t('country') + ' ' + '*'"
            v-model="formSearch.country"
            :values="countries"
            @input="(value)=> {
              _driver.country = value; 
              userRCS_selected = null; 
              formSearch.sapCountry = formSearch.country == 'FW' ? 'FR' : value
            }"

            :disabled="false"
          />
          <BlockInfo
            :title="$t('name')"
            v-if="!manualSapIdInsert"
            v-model="formSearch.name"
            @input-change="userRCS_selected = null"
            :disabled="false"
          />
          <BlockInfo
            :title="$t('license')"
            v-if="!manualSapIdInsert"
            v-model="formSearch.license"
            @input-change="userRCS_selected = null"
            :disabled="false"
          />

          <div v-if="_usersRCS.length > 0" class="cardDriver-usersRCS">
            <div class="cardDriver-subtitle">{{$t('select_user')}}:</div>

            <div
              class="cardDriver-inputGroup"
              v-for="user in _usersRCS"
              :key="user.driverNumberId"
            >
              <input
                type="radio"
                class="btn-check"
                name="userRCS"
                :id="`userRCS-${user.driverNumberId}`"
                autocomplete="off"
                style="display: none"
              />
              <label
                class="btn btn-outline-success btn-block"
                :for="`userRCS-${user.driverNumberId}`"
                @click="userRCS_selected = user"
                >{{ user.name }} <br />
                SapID: {{ user.driverNumberId }}</label
              >
            </div>
          </div>

          <div class="cardDriver-subtitle" v-if="userRCS_selected">
            You are going to associate driver <b>{{ _driver.name }}</b> with
            user
            <b>{{ userRCS_selected.name }}</b>
          </div>

          <div class="cardDriver-sectionButtons">
            <CButton
              v-if="!userRCS_selected && !manualSapIdInsert"
              :color="'success'"
              size="lg"
              class="cardDriver-button"
              @click="searchUserRCS()"
              >Search</CButton
            >
            <!-- <CButton
              v-else
              :color="btnEventType"
              size="lg"
              class="cardDriver-button"
              @click="assingUserRCS()"
              >Assign user</CButton
            > -->
          </div>
        </CCollapse>
      </div>

      <!-- CARRIERS SECTION -->
      <div v-if="(userRol === 'superadmin' || userRol === 'countryAdmin') && _carriers">
        <div
          class="cardDriver-subtitle btn-plus"
          @click="collapseCarriers = !collapseCarriers"
        >
          {{$t('carrier_selection')}}
          <CIcon name="cil-plus" />
        </div>

        <CCollapse :show="showCollapseCarriers == collapseCarriers">
          <BlockInfo
            :title="$t('carrier_id')"
            v-model="carrierFormSearch.id"
            @input-change="carrier_selected = null"
            :disabled="false"
          />
          <BlockInfo
            :title="$t('carrier_name')"
            v-model="carrierFormSearch.name"
            @input-change="carrier_selected = null"
            :disabled="false"
          />

          <div v-if="_carriers.length > 0" class="cardDriver-usersRCS">
            <div class="cardDriver-subtitle">{{$t('select_carrier')}}:</div>

            <div
              class="cardDriver-inputGroup"
              v-for="carrier in _carriers"
              :key="carrier.vendor"
            >
              <input
                type="radio"
                class="btn-check"
                name="carrier"
                :id="`carrier-${carrier.vendor}`"
                autocomplete="off"
                style="display: none"
              />
              <label
                class="btn btn-outline-success btn-block"
                :for="`carrier-${carrier.vendor}`"
                @click="carrier_selected = carrier"
                >{{ carrier.general.name }} <br />
                ID: {{ carrier.vendor }}</label
              >
            </div>
          </div>

          <div class="cardDriver-subtitle" v-if="carrier_selected">
            You are going to associate the driver with carrier
            <b>{{ carrier_selected.general.name }}</b>
          </div>

          <div class="cardDriver-sectionButtons">
            <CButton
              :color="'danger'"
              size="lg"
              class="cardDriver-button"
              @click="removeCarrier()"
              >{{ $t('remove_carrier') }}</CButton
            >
            <CButton
              v-if="!carrier_selected"
              :color="'success'"
              size="lg"
              class="cardDriver-button"
              @click="searchCarrier()"
              >{{$t('search_carrier')}}</CButton
            >
          </div>
        </CCollapse>
      </div>

      

      <div
        class="cardDriver-subtitle btn-plus"
        @click="collapseBottom = !collapseBottom"
      >
        {{$t('driver_details')}}
        <CIcon name="cil-plus" />
      </div>
      <CCollapse :show="showCollapseBottom == collapseBottom">
        <BlockInfo
          :title="$t('driver_first_name') + ' ' + '*'"
          v-model="_driver.firstName"
          :disabled="inputsDisabled"
          @input="updateSapValues()"
        />
        <BlockInfo
        :title="$t('driver_last_name') + ' ' + '*'"
          v-model="_driver.lastName"
          :disabled="inputsDisabled"
          @input="updateSapValues()"
        />
        <BlockInfo
          :title="$t('email')"
          v-model="_driver.email"
          :disabled="inputsDisabled"
        />
        <BlockInfo
          :title="$t('license')"
          v-model="_driver.license"
          :disabled="inputsDisabled"
        />
        <BlockInfo
        :title="$t('phone') + ' ' + '*'"
          v-model="_driver.phoneNumber"
          :disabled="inputsDisabled"
          @input="updateSapValues()"
        />
        <!-- <BlockInfo
          title="Language"
          v-model="_driver.language"
          :disabled="inputsDisabled"
        /> -->
         <!-- <BlockInfoSelect
          title="Language"
          v-model="_driver.language"
          :values="availableLanguages"
          :disabled="inputsDisabled"
        /> -->
        <BlockInfo :title="$t('sap_id')" v-model="_driver.sapId" @input="updateMobiUserNo()" :disabled="!manualSapIdInsert" /> 
        <BlockInfo
        :title="$t('sap_driver_name')"
          v-model="_driver.rcsUserName"
          v-if="false"
          :disabled="true"
        />
        <BlockInfo
          :title="$t('carrier_id')"
          v-model="_driver.carrierSapId"
          :disabled="true"
        />
        <BlockInfo
          :title="$t('carrier_name')"
          v-model="_driver.carrierSap"
          :disabled="true"
        />
        <BlockInfo
          :title="$t('mobi_user_number') + ' ' + '*'"
          v-model="_driver.mobiUserNo"
          :disabled="true"
          v-if="showMobiUserNo"
        />
        <BlockInfo :title="$t('country') + ' *'" v-model="_driver.country" :disabled="true" />
        <!-- <BlockInfo
          title="Id Device"
          v-model="_driver.idDevice"
          :disabled="true"
        />
        <BlockInfo
          title="Name Device"
          v-model="_driver.nameDevice"
          :disabled="true"
        />
        <BlockInfo
          title="OS Version"
          v-model="_driver.osVersion"
          :disabled="true"
        /> -->
        <!-- <BlockInfo title="Version" v-model="_driver.version" :disabled="true" /> -->
        <BlockInfo
          :title="$t('products') +  ' *'"
          v-model="_driver.products"
          :disabled="true"
        />
        <div v-if="_driver.products.length == 0">
          <CAlert :color="'warning'">
            {{$t('select_least_product')}}.
          </CAlert>
        </div>
        <div >
          <CAlert :color="'warning'">
            Fields marked with * are mandatory
          </CAlert>
        </div>
        <div class="cardDriver-sectionButtons">
          <CButton
            color="white"
            size="lg"
            class="cardDriver-button"
            @click="sendEvent()"
            >{{ $t('cancel') }}</CButton
          >
          <CButton
            :color="'success'"
            size="lg"
            class="cardDriver-button"
            @click="actionEvent()"
            :disabled="!showSaveButton"
            >{{$t('save')}}</CButton
          >
        </div>
      </CCollapse>
    </div>
    <div class="cardDriverShadow" @click="sendEvent()"></div>
  </div>
</template>

<script>
import BlockInfo from "./BlockInfo";
import BlockInfoSelect from "./BlockInfoSelect";

export default {
  name: "CardDriverNew",
  components: {
    BlockInfo,
    BlockInfoSelect,
  },
  props: {
    // driver: Object,
    usersRCS: Array,
    carriers: Array,
    title: String,
    notification: String,
    notificationType: String,

    inputsDisabled: Boolean,
    countryCodes: Array,
    systemsByCountry: Array,

    showCollapseTop: Boolean,
    showCollapseCarriers: Boolean,
    showCollapseProducts: Boolean,
    showCollapseBottom: Boolean,
  },
  computed: {
    console: () => console,
    _driver() {
      return (
        this.driver
      );
    },

    _usersRCS() {
      return this.usersRCS;
    },
    _carriers() {
      return this.carriers;
    },
    countries() {
      if (this.countryCodes.length > 1) return this.countryCodes.slice(1);
      //["PL"]
      else {
        return this.countryCodes;
      }
    },
    showMobiUserNo() {
      let hasZonda = false
      if (this.formSearch.country != '' && this.formSearch.country != undefined) {
        let data = this.systemsByCountry.find(e => e.code == this.formSearch.country)
        let it = data.systems.some(item => this.selectedProducts.includes(item.product.toLowerCase()) && item.system == 'ZONDA');
        hasZonda = it
      }
      return hasZonda
    },
    manualSapIdInsert(){
      return ((this.formSearch.sapCountry == 'GB' || this.formSearch.sapCountry == 'FR') && this.selectedProducts.includes('aggregates')) || (this.formSearch.sapCountry == 'FR' && this.selectedProducts.includes('concrete'));
    },
    showSaveButton(){
      this.console.log(this._driver.sapId, this._driver.firstName, this._driver.lastName, this._driver.phoneNumber,this._driver.country, this._driver.mobiUserNo ,this._driver.products)
      return this._driver.sapId && this._driver.products.length > 0 && this._driver.firstName && this._driver.lastName && this._driver.phoneNumber && this._driver.country && this._driver.mobiUserNo;
    },
    userRol() {
      return JSON.parse(localStorage.getItem("userRol"));
    }
  },
  watch: {
    userRCS_selected: function (val) {
      if(!val){
        this._driver.sapId = null;
        this._driver.license = null;
        this._driver.rcsUserName = null;
        this._driver.mobiUserNo = null;
        this._driver.country = null;
        return;
      }
      this._driver.sapId = val.driverNumberId;
      this._driver.license = val.licenseNumber;
      this._driver.rcsUserName = val.name;
      this._driver.mobiUserNo = val.mobiUserNo;
      this._driver.country = this.formSearch.country;
    },
    carrier_selected: function (val) {
      if(!val){
        this._driver.carrierSapId = null;
        this._driver.carrierSap = null;
        return;
      }
      this._driver.carrierSapId = val.vendor;
      this._driver.carrierSap = val.general.name;
    },
    selectedProducts: function (val) {
      this._driver.products = val;
      this.showSaveButton.$forceUpdate()
      this.updateSapValues()

    },
    _driver: {
          handler: function(newValue) {
          },
          deep: true
      },
      showSaveButton: {
          handler: function(newValue) {
            
          },
          deep: true,
          immediate: true
      },
    manualSapIdInsert: function(newval){
     if(newval){
     this.updateSapValues()
     }else{
      this._driver.rcsUserName =  this.userRCS_selected ? this.userRCS_selected.name : null;
      this._driver.sapId = this.userRCS_selected ?this.userRCS_selected.driverNumberId: null;
     }
    },
  },
  data() {
    return {
      collapseTop: true,
      collapseCarriers: true,
      collapseProducts: false,
      collapseBottom: true,
      availableProducts: ["concrete", "cement", "aggregates"],
      availableLanguages: [
        { _key: "en", value: "English" },
        { _key: "pl", value: "Polish" }
      ],
      selectedProducts: [],
      formSearch: {
        name: "",
        country: "",
        sapCountry: "",
        license: "",
      },
      userRCS_selected: null,
      carrierFormSearch: {
        name: "",
        id: "",
      },
      carrier_selected: null,
      driver: {},

    };
  },
  created() {
    this.driver = {
      products: []
    }
    this.checkCarrierInfo("carrierInfo")
    // this.selectedProducts = this.driver.products.slice();
  },
  methods: {
    sendEvent() {
      (this.classHidden = "hidden"), this.$emit("close-card-driver", false);
    },
    actionEvent() {
      // TODO: aqui devolveremos el emit con los datos de formulario para actualizar
      this._driver.language = 'en';
       this.$emit("create-driver", this._driver);
    },
    checkCarrierInfo(val) {
      let carrierInfo = JSON.parse(localStorage.getItem(val))
      if (carrierInfo) {
        this.driver.carrierSapId = carrierInfo.id
        this.driver.carrierSap = carrierInfo.name
      }
    },
    saveProducts() {
      this.$emit("save-products", {
        products: this.selectedProducts,
        email: this._driver.email,
      });
    },
    updateMobiUserNo(){
      if(this.manualSapIdInsert){
        this.driver.mobiUserNo ='mobi_lma_' + this._driver.sapId;
      }
      this.$forceUpdate();
    },
    updateSapValues(){
      if(this.manualSapIdInsert){
        this._driver.rcsUserName = (this._driver.firstName||'').trim() + ' ' + (this._driver.lastName||'').trim();
        var sapID =(this._driver.firstName ||'') + '' + (this._driver.lastName||'') + '' + (this._driver.phoneNumber||'');
        sapID = sapID.toUpperCase()
        sapID = sapID.split('+').join('')
        sapID = sapID.split(' ').join('')
        this._driver.sapId = sapID;
        this._driver.mobiUserNo ='mobi_lma_' + sapID;
      }
    },
    searchUserRCS() {
      // TODO: validamos los campos para hacer la busqueda de rcs

      if (this.formSearch.country == "") {
        this.$emit("card-driver-error", "The country is required");
        return;
      }

      this.$emit("search-user-rcs", this.formSearch);
    },
    assingUserRCS() {
      this.$emit("assing-user-rcs", {
        userRCS: this.userRCS_selected,
        email: this._driver.email,
        driverSelected: this._driver,
      });
    },
    searchCarrier() {
      if(this.carrierFormSearch.id) {
        this.$emit("search-carrier", this.carrierFormSearch);
      }
    },
    removeCarrier() {
      this.carrier_selected = null;
    }
  },
};
</script>

<style scoped>
.cardDriverShadow {
  width: 100%;
  height: 100vh;
  background: #000015;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10100;
}
.cardDriver {
  width: 100%;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000;
}
.cardDriver-content {
  position: absolute;
  right: 0;
  top: 0;
  width: 90%;
  height: 100vh;
  max-width: 800px;
  background: #fff;
  box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.25);
  z-index: 10200;
  padding-top: 53.78px;
  padding-left: 3em;
  padding-right: 3em;

  overflow-y: auto;
}
.btnClose {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1.5em;
  cursor: pointer;
}

.cardDriver-title {
  font-size: 20px;
  margin-bottom: 1em;
}
.cardDriver-subtitle {
  width: 100%;
  font-size: 16px;
  /* margin-top: 1em;
  margin-bottom: 1em; */
  padding: 1em;
  line-height: 1.3;
}
.btn-plus {
  margin-top: 1em;
  margin-bottom: 1em;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
.btn-plus:hover {
  background: rgba(0, 0, 0, 0.05);
}

.cardDriver-usersRCS {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.cardDriver-inputGroup {
  width: 25%;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.cardDriver-sectionButtons {
  padding: 1em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.cardDriver-button {
  margin-left: 1em;
  font-size: 14px;
}

.product-selector label,
.product-selector input {
  cursor: pointer;
  font-size: 16px;
}
.product-selector input {
  position: relative;
  margin-right: 6px;
}
.bockInput:disabled {
  background: #e9e9ed;
}
</style>